.App {
  text-align: center;
}

.player-root {
  width: 80%;
  margin: 24px auto;
}

a {
  color: inherit;
  text-decoration: none;
}
